

const Seminar = () => {
  return (
    <div className="flex flex-col w-full py-[80px] md:py-[100px] px-4 xl:px-0 bg-white">
      <div className="max-w-[1200px] grid grid-cols-1 md:grid-cols-2 items-center gap-16 mx-auto w-full">
        <div className="">
          <h2 className="font-bold text-[30px] mb-4">Musical arts concerts</h2>
          <div className="mb-4">
            <p className="text-[16px] leading-[27px]">
              Periodically, we organise musical arts concerts at the provincial,
              national, and international levels. These serve as avenues for
              promoting our indigenous cultures within and beyond the country,
              further propagating the original songs premiered at our various
              festivals, while providing performance platforms to help our
              members further develop their musical arts performance skills. In
              addition, these events help in fostering shared values, and
              promoting inter-ethnic tolerance and integration amongst our
              membership.
            </p>
          </div>
        </div>
        <img src="/concert.png" alt="hero" className="rounded-md w-full h-[400px] object-cover" />
      </div>
    </div>
  );
};

export default Seminar;
