import React, { useState } from "react";
import {
  AiFillYoutube,
  AiOutlineInstagram,
} from "react-icons/ai";
import { CiMail } from "react-icons/ci";
import { FaFacebook } from "react-icons/fa";
import { useFormik } from "formik";
import { contactSchema } from "../../schemas";
import { client } from "../../client";
import { toast } from "react-toastify";


const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, handleChange, touched, errors, values, resetForm } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: contactSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        await client.create({
          _type: "contact",
          ...values,
        });
        toast.success("We have received your message!");
        setIsLoading(false);
        resetForm()
      } catch (error) {
        toast.error("Error submitting details");
        console.log(error);
        setIsLoading(false)
      }
    },
  });

  return (
    <div className="flex flex-col py-[40px] w-full bg-[#f6f6f6] px-6 md:px-0">
      <div className="max-w-[1200px] mx-auto w-full">
        <h2 className="text-[30px] font-semibold mb-8">
          Feel free to contact us
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mb-[30px] w-full">
          <form onSubmit={handleSubmit} className="flex flex-col w-full gap-4">
            <div className="flex gap-4 w-full">
              <div className="w-1/2">
                <input
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  placeholder="First Name"
                  className="border border-gray-300 w-full rounded-none px-3 py-3 focus:outline-none"
                />
                {touched.firstName && errors.firstName && (
                  <div className="text-red-500 mt-2 text-sm">
                    {errors.firstName}
                  </div>
                )}
              </div>
              <div className="w-1/2">
                <input
                  type="text"
                  value={values.lastName}
                  name="lastName"
                  onChange={handleChange}
                  placeholder="Last Name"
                  className="border border-gray-300 w-full rounded-none px-3 py-3 focus:outline-none"
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="w-1/2">
                <input
                  type="email"
                  value={values.email}
                  name="email"
                  onChange={handleChange}
                  placeholder="Email"
                  className="border border-gray-300 rounded-none w-full px-3 py-3 focus:outline-none"
                />
                {touched.email && errors.email && (
                  <div className="text-red-500 text-sm mt-2">
                    {errors.email}
                  </div>
                )}
              </div>
              <div className="w-1/2">
                <input
                  type="text"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  placeholder="Phone"
                  className="border border-gray-300 w-full rounded-none px-3 py-3 focus:outline-none"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <input
                type="text"
                placeholder="Subject"
                name="subject"
                value={values.subject}
                onChange={handleChange}
                className="border border-gray-300 rounded-none px-3 py-3 focus:outline-none"
              />
            </div>
            <div className="flex flex-col">
              <textarea
                cols={10}
                rows={4}
                name="message"
                value={values.message}
                placeholder="Message..."
                onChange={handleChange}
                className="border border-gray-300 rounded-none px-3 py-4 focus:outline-none"
              />
              {touched.message && errors.message && (
                <div className="text-red-500 text-sm mt-2">
                  {errors.message}
                </div>
              )}
            </div>
            <button
              type="submit"
              disabled={isLoading}
              className={`${
                isLoading ? "bg-opacity-50" : "bg-opacity-100"
              } bg-primary px-4 py-2 rounded-none text-white hover:bg-black w-1/2 cursor-pointer`}
            >
              Send
            </button>
          </form>
          <div className="flex flex-col gap-8 p-4">
            <div className="flex gap-4">
              <CiMail color="#13620B" size={30} />
              <h2 className="text-accent text-[16px]">
                film.nigeria.mail@gmail.com
              </h2>
            </div>
            <div className="flex gap-4">
              <FaFacebook color="#13620B" size={30} />
              <a
                href="https://www.facebook.com/film.nigeria"
                className="text-accent text-[16px]"
              >
                https://www.facebook.com/film.nigeria
              </a>
            </div>
            <div className="flex gap-4">
              <AiOutlineInstagram color="#13620B" size={30} />
              <a
                href="https://www.instagram.com/film_nigeria"
                className="text-accent text-[16px]"
              >
                https://www.instagram.com/film_nigeria
              </a>
            </div>
            <div className="flex gap-4">
              <AiFillYoutube color="#13620B" size={30} />
              <a
                href="https://www.youtube.com/filmnigeria"
                className="text-accent text-[16px]"
              >
                https://www.youtube.com/filmnigeria
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
