import { FiMenu } from "react-icons/fi";
import { FaTimes } from "react-icons/fa";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FaCaretDown } from "react-icons/fa";

const navLinks = [
  { name: "About", url: "/" },
  { name: "Activities", url: "/activities" },
  {
    name: "Our Team",
    url: "/team",
    submenu: [
      { name: "BOT", url: "/team/bot" },
      { name: "National Exco", url: "/team/national-exco" },
    ],
  },
  { name: "Partners", url: "/partners" },
  { name: "News", url: "/news" },
  { name: "Contact Us", url: "/contact" },
];

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const submenuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (submenuRef.current && !submenuRef.current.contains(event.target)) {
        setIsSubMenuOpen(false); // Close the submenu
      }
    }

    // Attach the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [submenuRef]);

  return (
    <div className="h-[75px] px-[15px] flex flex-col items-center md:px-0 py-[24px] bg-gray-800">
      <div className="max-w-[1280px] w-full mx-auto flex justify-between items-center h-full">
        <Link href="/" className="flex items-center">
          <img
            src="/logo.png"
            alt="logo"
            className="h-fit w-[80px] md:w-[100px] object-cover"
          />
          <h2 className="text-white text-[20px] font-bold ml-[-10px]">
            Film Nigeria
          </h2>
        </Link>
        <div className="hidden md:flex justify-center items-center gap-[47px] text-white">
          {navLinks.map((link) => {
            if (link.submenu) {
              // Render a link with a submenu
              return (
                <div className="relative flex items-center" key={link.name}>
                  <button
                    onClick={() => setIsSubMenuOpen((prev) => !prev)}
                    className="text-[18px] cursor-pointer font-medium hover:text-primary"
                  >
                    {link.name}
                  </button>
                  <FaCaretDown
                    color="white"
                    size={20}
                    className="cursor-pointer"
                    onClick={() => setIsSubMenuOpen((prev) => !prev)}
                  />
                  {isSubMenuOpen && (
                    <div
                      ref={submenuRef}
                      className="absolute top-12 flex flex-col gap-3 shadow w-[10rem] h-fit py-4 z-50 bg-primary p-4 rounded-md"
                    >
                      {link.submenu.map((subLink) => (
                        <Link
                          to={subLink.url}
                          key={subLink.name}
                          className="text-[16px] cursor-pointer font-medium"
                          onClick={() => setIsSubMenuOpen(false)}
                        >
                          {subLink.name}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              );
            } else {
              // Render a regular link
              return (
                <Link
                  to={link.url}
                  key={link.name}
                  className="text-[18px] cursor-pointer font-medium hover:text-primary"
                >
                  {link.name}
                </Link>
              );
            }
          })}
        </div>
        <div className="flex md:hidden overflow-hidden">
          <div className="cursor-pointer" onClick={() => setNav(true)}>
            <FiMenu style={{ color: "white", fontSize: "30px" }} />
          </div>
          <div
            className={
              nav
                ? "w-full h-full z-50 fixed left-0 top-0 bg-gray-900/70 text-white md:hidden backdrop-blur"
                : ""
            }
          >
            <div
              className={
                nav
                  ? "fixed w-full text-center bg-primary  left-0 top-0 bottom-0 text-white z-50 h-full p-10 py-10 ease-in duration-500"
                  : "fixed p-10 w-full left-[-100%] z-50 top-0 duration-1000 h-full ease-in"
              }
            >
              <div className="w-full flex justify-between items-center mb-[5rem]">
                <div
                  className="cursor-pointer p-0"
                  onClick={() => setNav(false)}
                >
                  <Link to="/">
                    <img
                      src="logo.jpg"
                      alt="site logo"
                      className="h-[50px] w-[50px] object-cover"
                    />
                  </Link>
                </div>
                <div className="cursor-pointer" onClick={() => setNav(false)}>
                  <FaTimes size={30} />
                </div>
              </div>
              <div className="flex flex-col text-white justify-between h-fit mb-[10px] z-50">
                {navLinks.map((link) => {
                  if (link.submenu) {
                    // Render a link with a submenu
                    return (
                      <div className="relative" key={link.name}>
                        <button className="text-[18px] cursor-pointer font-medium hover:text-primary">
                          {link.name}
                        </button>
                        <div className="absolute hidden group-hover:block">
                          {link.submenu.map((subLink) => (
                            <Link
                              to={subLink.url}
                              key={subLink.name}
                              className="block text-[16px] cursor-pointer font-medium hover:text-primary"
                              onClick={() => setNav(false)}
                            >
                              {subLink.name}
                            </Link>
                          ))}
                        </div>
                      </div>
                    );
                  } else {
                    // Render a regular link
                    return (
                      <Link
                        to={link.url}
                        key={link.name}
                        className="text-[18px] cursor-pointer font-medium hover:text-primary"
                      >
                        {link.name}
                      </Link>
                    );
                  }
                })}

                {/* <button
                  onClick={() => setNav(false)}
                  className="rounded-lg bg-white text-primary mx-auto w-[180px] px-[15px] py-[11px] text-[18px] font-nohemi"
                >
                  Join us
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
