import React from 'react'
import PartnersHero from '../components/partners/PartnersHero'
import Partner from '../components/partners/Partner'

const Partners = () => {
  return (
    <div>
        <PartnersHero />
        <Partner />
    </div>
  )
}

export default Partners