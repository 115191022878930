import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Footer from "./components/layout/Footer";
import Activities from "./pages/Activities";
import Partners from "./pages/Partners";
import News from "./pages/News";
import Articles from "./pages/Articles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NationalExcoTeam from "./components/team/NationalExcoTeam";
import BotTeam from "./components/team/BotTeam";

const Layout = () => {
  return (
    <div>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <About />,
      },
      {
        path: "/team/national-exco",
        element: <NationalExcoTeam />,
      },
      {
        path: "/team/bot",
        element: <BotTeam />,
      },
      {
        path: "/activities",
        element: <Activities />,
      },
      {
        path: "/partners",
        element: <Partners />,
      },
      {
        path: "/news",
        element: <News />,
      },
      {
        path: "/news/:id",
        element: <Articles />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
    ],
  },
]);

const App = () => {
  return (
    <div className="font-montserrat">
      <RouterProvider router={router} />
      <ToastContainer />
    </div>
  );
};

export default App;
