import React from "react";
import { client, urlFor } from "../../client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Article = () => {
  const { id } = useParams();
  const [news, setNews] = useState(null);

  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        const query = `*[_type == "announcement" && _id == "${id}"]`;
        const data = await client.fetch(query);
        setNews(data[0]);
      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    };

    fetchNewsData();
  }, [id]);

  if (!news) return <div>Loading...</div>;
  return (
    <div className="flex flex-col pt-[50px] pb-[80px] bg-[#f6f6f6] px-6">
      <div className="max-w-[1000px] mx-auto w-full">
        {news.imgUrl && <img src={urlFor(news.imgUrl)} alt="news detail" />}
        <h2 className="text-black font-bold text-[24px] my-6">{news.title}</h2>
        <p>{news.description}</p>
      </div>
    </div>
  );
};

export default Article;
