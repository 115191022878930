import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const WhoWeAre = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="flex flex-col w-full py-[70px] md:py-[100px] px-4 md:px-0">
      <div className="max-w-[1200px] grid grid-cols-1 md:grid-cols-2 items-center gap-16 mx-auto w-full">
        <div
          className=""
          data-aos="fade-up"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
          data-aos-once="true"
        >
          <h2 className="font-bold text-[30px] mb-4">Who We Are</h2>
          <div className="mb-4">
            <p className="text-[16px] leading-[27px] font-montserrat">
              The Forum for the Inculturation of Liturgical Music (FILM) is a
              lay apostolate of the Catholic Church in Nigeria dedicated to
              enriching the Catholic liturgy with sacred music composed in
              Nigerian indigenous languages and reflecting Nigerian cultural
              idioms. Currently functioning at the level of Catholic
              Chaplaincy/Parish choirs in Nigerian tertiary institutions, we are
              committed to leveraging the rich indigenous cultures and values,
              not only as potent liturgical instruments, but also as agents for
              promoting ethnocultural tolerance, integration, unity and peaceful
              coexistence, especially among our majorly youthful members.
            </p>
          </div>
          <Link to="/activities">
            <button className="bg-primary text-white py-2 px-4 hover:bg-black transform transition duration-500 ease-in-out hover:scale-110">
              Learn More
            </button>
          </Link>
        </div>
        <img
          src="/who.jpg"
          alt="hero"
          className="rounded-md"
          data-aos="zoom-in"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
          data-aos-once="true"
        />
      </div>
    </div>
  );
};

export default WhoWeAre;
