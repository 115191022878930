import React from "react";
import { BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";
import jos from "../assets/jos.jpg";
import prof from "../assets/prof.jpg";
import ann from "../assets/ann.jpg";
import abel from "../assets/abel.jpg";
import marie from "../assets/marie.jpg";
import king from "../assets/king.jpg";
import theo from "../assets/theo.jpg";
import bon from "../assets/bon.jpg";
import des from "../assets/des.jpg";
import anth from "../assets/anth.jpg";

const BotTeam = () => {
  const teamMembers = [
    // {
    //   name: "Prof. Geoffrey Obinna Anoliefo",
    //   position: "Chairman",
    //   imageUrl: prof,
    // },
    {
      name: "Rev. Fr. Joseph Onome Oghenejabor",
      position: "Spiritual Director",
      imageUrl: jos,
    },
    {
      name: "Miss Anne Mgbolise Ottah",
      position: "Secretary",
      imageUrl: ann,
    },
    {
      name: "Mr. Abel Obaje",
      position: "Member (National Coordinator)",
      imageUrl: abel,
    },
    {
      name: "Rev. Sr. Prof. Marie Agatha Ozah, HHCJ",
      position: "Member (National Coordinator)",
      imageUrl: marie,
    },
    {
      name: "Pharm. Kingsley Anetor Francis Odia, KSM",
      position: "Member",
      imageUrl: king,
    },
    {
      name: "Dr. Chi-kadibia Theophilus Ukoma, KSJI",
      position: "Member",
      imageUrl: theo,
    },
    {
      name: "Dr. Boniface Sunday Akpan (Inyang) ",
      position: "Member",
      imageUrl: bon,
    },
    {
      name: "Mr. Desmond Onyemuche Ihuoma",
      position: "Member",
      imageUrl: des,
    },
    {
      name: "Engr. Anthony Uchechukwu Okoro",
      position: "Member",
      imageUrl: anth,
    },
  ];
  return (
    <div className="pt-[50px] pb-[70px] px-8 bg-[#f6f6f6]">
      <div className="max-w-[1280px] mx-auto">
        <div className="relative bg-activities bg-cover bg-center flex flex-col h-[150px] text-white justify-center items-center">
          <div className="absolute bg-[#000000b1] inset-0 w-full z-10"></div>
          <h2 className="font-bold text-[25px] text-white z-20">BOT Team</h2>
          <div className="flex items-center gap-2 z-20 text-white">
            <Link to="/">Home</Link>
            <BiChevronRight color="white" />
            <p className="text-accent font-semibold">BOT</p>
          </div>
        </div>
        <div className="flex justify-center mt-16">
          <div className="bg-white shadow-lg pb-6 flex flex-col items-center text-center">
            <img
              src={prof}
              alt="prof"
              className="w-[400px] h-[350px] object-cover object-top mb-4"
            />
            <h3 className="text-xl font-semibold my-2">
              Prof. Geoffrey Obinna Anoliefo
            </h3>
            <p className="text-indigo-600">Chairman</p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-center mt-8">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className="bg-white shadow-lg pb-6 flex flex-col items-center text-center"
            >
              <img
                src={member.imageUrl}
                alt={member.name}
                className="w-full h-[350px] object-cover object-top mb-4"
              />
              <h3 className="text-xl font-semibold my-2">{member.name}</h3>
              <p className="text-indigo-600">{member.position}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BotTeam;
