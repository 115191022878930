import React from "react";
import WhoWeAre from "../components/about/WhoWeAre";
import WhatWeDo from "../components/about/WhatWeDo";
import How from "../components/about/How";
import WhereWeAre from "../components/about/WhereWeAre";
import Structure from "../components/about/Structure";

const About = () => {
  return (
    <div>
      <WhoWeAre />
      <WhatWeDo />
      <How />
      <Structure />
      <WhereWeAre />
    </div>
  );
};

export default About;
