import React, { useEffect } from "react";
import { AiFillCaretRight } from "react-icons/ai";
import AOS from "aos";
import "aos/dist/aos.css";

const Structure = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      name="choose-us"
      className="flex flex-col justify-center py-[80px] px-4 md:px-0 bg-[#f5f5f5]"
    >
      <div
        className="max-w-[1000px] grid grid-cols-1 md:grid-cols-2 items-center gap-16 mx-auto w-full"
        data-aos="fade-up"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
        data-aos-once="true"
      >
        <img src="/film.png" alt="hero" className="rounded-md" />
        <div>
          <h2 className="font-bold text-[30px] mb-4">Our Structure</h2>
          <p className="text-[16px] leading-[27px]">
            Following the provincial organisation of the Catholic Church in
            Nigeria, FILM is structured into the following nine (9) provinces:
          </p>
          <div className="flex flex-col gap-4 my-4">
            <div className="flex -gap-2">
              <AiFillCaretRight color="#13620B" size={20} />
              <p>Abuja</p>
            </div>
            <div className="flex -gap-2">
              <AiFillCaretRight color="#13620B" size={20} />
              <p>Benin</p>
            </div>
            <div className="flex -gap-2">
              <AiFillCaretRight color="#13620B" size={20} />
              <p>Calabar</p>
            </div>
            <div className="flex -gap-2">
              <AiFillCaretRight color="#13620B" size={20} />
              <p>Ibadan</p>
            </div>
            <div className="flex -gap-2">
              <AiFillCaretRight color="#13620B" size={20} />
              <p>Jos</p>
            </div>
            <div className="flex -gap-2">
              <AiFillCaretRight color="#13620B" size={20} />
              <p>Kaduna</p>
            </div>
            <div className="flex -gap-2">
              <AiFillCaretRight color="#13620B" size={20} />
              <p>Lagos</p>
            </div>
            <div className="flex -gap-2">
              <AiFillCaretRight color="#13620B" size={20} />
              <p>Onitsha</p>
            </div>
            <div className="flex -gap-2">
              <AiFillCaretRight color="#13620B" size={20} />
              <p>Owerri</p>
            </div>
          </div>
          <p>
            Our administrative organs include a Spiritual Director, a Board of
            Trustees, a General Congress, a Delegates’ Consultative Council, and
            a National Executive Council
          </p>
        </div>
      </div>
    </div>
  );
};

export default Structure;
