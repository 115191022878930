import React from "react";
import {
  AiFillLinkedin,
  AiFillYoutube,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from "react-icons/ai";
import { BsBrowserChrome } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";

const Partner = () => {
  return (
    <div className="flex flex-col w-full py-[80px] md:py-[100px] px-4 xl:px-0">
      <div className="max-w-[1280px] mx-auto">
        <div className="flex flex-col max-w-[600px] mx-auto items-center gap-4">
          <h2 className="text-center font-bold text-black text-[35px]">
            We Work With the Best Partners
          </h2>
          <p className="text-center text-black mt-4">
            As an organization dedicated to enriching the Catholic liturgy with
            sacred music composed in Nigerian, we work with the best partners to
            make this vision a reality
          </p>
        </div>
        <div className="grid grid-cols-2 mt-8 md:mt-0 md:grid-cols-3 gap-10 md:gap-6">
          <div className="flex flex-col items-center justify-center">
            <img src="/music.jpg" className="w-[220px]" alt="music" />
            <div className="flex flex-col gap-4 justify-center items-center">
              <h2 className="font-bold text-black text-center text-[20px]">
                Department of Music, University of Port Harcourt:
              </h2>
              <div className="flex items-center gap-4">
                <a
                  href="https://www.facebook.com/music.uniport"
                  className="text-accent text-[16px]"
                >
                  <FaFacebook color="#13620B" size={30} />
                </a>
                <a
                  href="https://www.youtube.com/@music_uniport"
                  className="text-accent text-[16px]"
                >
                  <AiFillYoutube color="#13620B" size={30} />
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <img
              src="/art.png"
              className="w-[150px] object-cover"
              alt="music"
            />
            <div className="flex flex-col gap-2 justify-center items-center mt-[-10px]">
              <h2 className="font-bold text-black text-[20px] text-center">
                Don Bosco Art43 Media
              </h2>
              <div className="flex gap-4">
                <a
                  href="https://web.facebook.com/donboscoradio9ja"
                  className="text-accent text-[16px]"
                >
                  <FaFacebook color="#13620B" size={30} />
                </a>
                <a
                  href="https://www.instagram.com/donboscoonlineradio"
                  className="text-accent text-[16px]"
                >
                  <AiOutlineInstagram color="#13620B" size={30} />
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <img src="/media.png" className="object-cover" alt="music" />
            <h2 className="font-bold text-black text-[20px] mt-[-20px] md:mt-[-65px]">
              Clayfix Media
            </h2>
            <div className="flex items-center gap-4 ">
              <a
                href="https://instagram.com/clayfix_media"
                className="text-accent text-[16px]"
              >
                <AiOutlineInstagram color="#13620B" size={30} />
              </a>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <img src="/vision.png" className="object-cover" alt="music" />
            <h2 className="font-bold text-black text-center text-[20px] mt-[10px] mb-2">
              Sonic Vision Multimedia
            </h2>
            <div className="flex items-center gap-4">
              <a
                href="https://www.facebook.com/sonic.vision.multimedia"
                className="text-accent text-[16px]"
              >
                <FaFacebook color="#13620B" size={30} />
              </a>
              <a
                href="https://www.linkedin.com/company/sonic-vision-multimedia"
                className="text-accent text-[16px]"
              >
                <AiFillLinkedin color="#13620B" size={30} />
              </a>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <img
              src="/mixline.png"
              className="object-cover w-[150px]"
              alt="music"
            />
            <h2 className="font-bold text-black text-[20px] mt-[10px] mb-2">
              Spane5 Mixline
            </h2>
            <div className="flex items-center gap-4">
              <a
                href="https://www.spane5mixline.com"
                className="text-accent text-[16px]"
              >
                <BsBrowserChrome color="#13620B" size={30} />
              </a>
              <a
                href="https://www.instagram.com/spane5onthemix"
                className="text-accent text-[16px]"
              >
                <AiOutlineInstagram color="#13620B" size={30} />
              </a>
              <a
                href="https://www.twitter.com/spane5mixline"
                className="text-accent text-[16px]"
              >
                <AiOutlineTwitter color="#13620B" size={30} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partner;
