import React from "react";
import ArticlesHero from "../components/articles/ArticlesHero";
import Article from "../components/articles/Article";

const Articles = () => {
  return (
    <div>
      <ArticlesHero />
      <Article />
    </div>
  );
};

export default Articles;
