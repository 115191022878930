import * as Yup from "yup";

export const contactSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string().email("Invalid email format").required("Required"),
  phone: Yup.string()
    .matches(/^\+?\d[\d-. ]{7,}$/, "Phone number is not valid")
    .required("Phone number is required"),
  subject: Yup.string(),
  message: Yup.string().required("Message is required"),
});
