import React from "react";
import NewsHero from "../components/news/NewsHero";
import NewsCard from "../components/news/NewsCard";

const News = () => {
  return (
    <div>
      <NewsHero />
      <NewsCard />
    </div>
  );
};

export default News;
