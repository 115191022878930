import React from "react";
import { BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";
// import Sort from "./Sort";

const ContactHero = () => {
  return (
    <div className="py-[50px] px-8 bg-[#f6f6f6]">
      <div className="max-w-[1280px] mx-auto">
        <div className="relative bg-activities bg-cover bg-center flex flex-col h-[150px] text-white justify-center items-center">
          <div className="absolute bg-[#000000b1] inset-0 w-full z-10"></div>
          <h2 className="font-bold text-[25px] text-white z-20">
            Contact Page
          </h2>
          <div className="flex items-center gap-2 z-20 text-white">
            <Link to="/">Home</Link>
            <BiChevronRight color="white" />
            <p className="text-accent font-semibold">Contact</p>
          </div>
        </div>
        {/* <Sort /> */}
      </div>
    </div>
  );
};

export default ContactHero;
