
const Seminar = () => {
  return (
    <div className="flex flex-col w-full py-[80px] md:py-[100px] px-4 xl:px-0 bg-[#F5F5F5]">
      <div className="max-w-[1200px] grid grid-cols-1 md:grid-cols-2 items-center gap-16 mx-auto w-full">
        <img
          src="/seminar.jpg"
          alt="hero"
          className="rounded-md w-full h-[350px] object-cover order-2 md:order-1"
        />
        <div className="order-1 md:order-2">
          <h2 className="font-bold text-[30px] mb-4">
            Annual seminar/workshop
          </h2>
          <div className="mb-4">
            <p className="text-[16px] leading-[27px]">
              Our annual seminar/workshop series serve as a knowledge and
              capacity development platform in the areas of the Church’s
              liturgy, music theory and practice, indigenous culture
              appreciation, ethnomusicological research methodology, and
              leadership. Based on a train the trainers approach, the series
              provide knowledge/skills transfer and mentorship opportunities to
              the leaders of our affiliate choirs. Our facilitators are drawn
              from a pool of seasoned liturgists, sacred musicians,
              ethnomusicologists, composers, conductors, and vocal/instrumental
              performers. These training sessions are also open to non-affiliate
              participants.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Seminar;
