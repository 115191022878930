import { useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const How = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const [showMore, setShowMore] = useState(false);
  return (
    <div className="flex flex-col w-full py-[80px] md:py-[100px] px-4 md:px-0">
      <div className="max-w-[1200px] grid grid-cols-1 md:grid-cols-2 items-center gap-16 mx-auto w-full">
        <div
          className=""
          data-aos="fade-right"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
          data-aos-once="true"
        >
          <h2 className="font-bold text-[30px] mb-4">How we started</h2>
          <div className="mb-4">
            <p className="text-[16px] leading-[27px]">
              On the 18th of March, 1995, at St. Albert’s Catholic Church,
              University of Benin, at the instance of Dr. (now Prof.) Geoffrey
              Obinna Anoliefo, and with the ecclesiastical approval of the then
              Archbishop of Benin, His Grace, Most Rev. Dr. Patrick Ebosele
              Ekpu, the Indigenous Music Competition for Catholic Parishes in
              Nigerian Universities was founded, as a melting point of the ivory
              towers to contribute to the development of apt liturgical music
              for the Nigerian Church, and was subsequently reported at the Jos
              meeting of the National Laity Council of Nigeria, that same year.
              At the second edition of the event, held at the University of
              Benin in 1997, the congress observed that some choirs showed
              tendencies of hiring composers because of the event’s competitive
              nature.
            </p>
            {showMore && (
              <p className="text-[16px] leading-[27px] mb-4">
                Considering that such unhealthy competition could detract from
                the primary objective, and following the demand of the National
                Laity Council, under Sir Dokitiri, that it become a lay
                apostolate group with local, provincial and national officers,
                Dr. Anoliefo, convened a meeting with some close associates, Mr.
                Kingsley Odia, Mr Eugene Inotu, Mr Raphael Onakorere, Mr. Joseph
                Mokwunye, Dr. Anthony Atimati and Dr. Prince Oyakhilome. It was
                resolved, at that meeting, to transform the event into a
                festival, and to name the choral body ‘Forum for the
                Inculturation of Liturgical Music (FILM)’. These resolutions
                were presented to the Benin Archdiocesan Laity Council under Sir
                Iredia, the Body that co-hosted the 1997 edition. The proposals
                were approved by the Archdiocesan Laity Council, and
                subsequently presented to the meeting of the National Laity
                Council in Orlu, where FILM was formally admitted as a lay
                apostolate body.
              </p>
            )}
          </div>
          <button
            className="bg-primary text-white py-2 px-4 hover:bg-black transform transition duration-500 ease-in-out hover:scale-110"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? "Read less" : "Read more"}
          </button>
        </div>
        <img
          src="/how.jpg"
          alt="hero"
          className="rounded-md w-ful"
          data-aos="fade-left"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
          data-aos-once="true"
        />
      </div>
    </div>
  );
};

export default How;
