import React, { useEffect } from "react";
import { AiFillCaretRight } from "react-icons/ai";
import AOS from "aos";
import "aos/dist/aos.css";

const WhereWeAre = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div
      name="choose-us"
      className="flex flex-col justify-center py-[80px] px-4 md:px-0"
    >
      <div className="max-w-[1200px] grid grid-cols-1 md:grid-cols-2 items-center gap-16 mx-auto w-full">
        <img
          src="/were.jpg"
          alt="hero"
          className="rounded-md"
          data-aos="zoom-in"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
          data-aos-once="true"
        />
        <div
          data-aos="fade-up"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
          data-aos-once="true"
        >
          <h2 className="font-bold text-[30px] mb-4">Where We Are</h2>
          <p className="text-[16px] leading-[27px] mb-4">
            From a small community of university based choirs in Benin Province,
            FILM has grown to include over forty (40) Catholic Chaplaincy/Parish
            choirs in Nigerian universities, polytechnics, and colleges. Within
            the 28 year period since we were founded, we have:
          </p>
          <div className="flex gap-2 mb-4">
            <AiFillCaretRight color="#13620B" size={20} />
            <p>Organised twelve (12) indigenous liturgical music festivals.</p>
          </div>
          <div className="flex gap-2 mb-4">
            <AiFillCaretRight color="#13620B" size={20} />
            <p>
              Held over twenty two (22) annual music and leadership
              seminars/workshops.
            </p>
          </div>
          <div className="flex gap-2 mb-4">
            <AiFillCaretRight color="#13620B" size={20} />
            <p>Hosted over forty (40) musical arts concerts.</p>
          </div>
          <div className="flex gap-2 mb-4">
            <AiFillCaretRight color="#13620B" size={20} />
            <p>
              Trained over three thousand (3000) young composers and choir
              leaders
            </p>
          </div>
          <div className="flex gap-2 mb-4">
            <AiFillCaretRight color="#13620B" size={20} />
            <p>
              Birthed over four hundred (400) original songs for Catholic
              liturgical worship
            </p>
          </div>
          <p>
            Every two years, over forty (40) new songs are added to our corpus
            of Nigerian indigenous Catholic liturgical music.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhereWeAre;
