import React from "react";
import { BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";
import abel from "../assets/abel.jpg";
import bon from "../assets/bon.png";
import chuk from "../assets/chuk.jpg";
import han from "../assets/han.jpg";
import pas from "../assets/pas.jpg";
import ver from "../assets/ver.jpg";
import mic from "../assets/mic.jpg";
import pet from "../assets/pet.jpg";

const NationalExcoTeam = () => {
  const teamMembers = [
    {
      name: "Dr. Bonaventure Michael Ukoaka",
      position: "National Secretary",
      imageUrl: bon,
    },
    {
      name: "Miss Vera Gbakumo Oweikpodor",
      position: "Deputy National Secretary",
      imageUrl: ver,
    },
    {
      name: "Mr. Paschal Chimankpam Anokwu",
      position: "National Music Director",
      imageUrl: pas,
    },
    {
      name: "Mr. Peter Ogala Atabor",
      position: "National Director of Finance",
      imageUrl: pet,
    },
    {
      name: "Mr. Chukwuma Mabi",
      position: "National Director of Publicity",
      imageUrl: chuk,
    },
    {
      name: "Handel Tochukwu Ezeibenne",
      position: "Ex Officio",
      imageUrl: han,
    },
  ];
  return (
    <div className="py-[50px] px-8 bg-[#f6f6f6]">
      <div className="max-w-[1280px] mx-auto">
        <div className="relative bg-activities bg-cover bg-center flex flex-col h-[150px] text-white justify-center items-center">
          <div className="absolute bg-[#000000b1] inset-0 w-full z-10"></div>
          <h2 className="font-bold text-[25px] text-white z-20">
            National Exco
          </h2>
          <div className="flex items-center gap-2 z-20 text-white">
            <Link to="/">Home</Link>
            <BiChevronRight color="white" />
            <p className="text-accent font-semibold">National Exco</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-center gap-8 mt-16">
          <div className="bg-white shadow-lg pb-6 flex flex-col items-center text-center">
            <img
              src={abel}
              alt="abel"
              className="w-[400px] h-[350px] object-cover object-top mb-4"
            />
            <h3 className="text-xl font-semibold my-2">Mr. Abel Obaje</h3>
            <p className="text-indigo-600">National Cordinator</p>
          </div>
          <div className="bg-white shadow-lg pb-6 flex flex-col items-center text-center">
            <img
              src={mic}
              alt="abel"
              className="w-[400px] h-[350px] object-cover object-top mb-4"
            />
            <h3 className="text-xl font-semibold my-2">
              Mr. Michael Chiedozie Uzomah
            </h3>
            <p className="text-indigo-600">Deputy National Cordinator</p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-center mt-16">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className="bg-white shadow-lg pb-6 flex flex-col items-center text-center"
            >
              <div className="w-full aspect-w-1 aspect-h-1 relative mb-4">
                <img
                  src={member.imageUrl}
                  alt={member.name}
                  className={`w-full h-[350px] ${
                    index === 1 || index === 3 ? "object-center" : "object-top"
                  } object-cover mb-4`}
                />
              </div>
              <h3 className="text-xl font-semibold my-2">{member.name}</h3>
              <p className="text-indigo-600">{member.position}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NationalExcoTeam;
