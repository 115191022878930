import React from "react";
import Festival from "../components/activities/Festival";
import Seminar from "../components/activities/Seminar";
import Concert from "../components/activities/Concert";
import ActivitiesHero from "../components/activities/ActivitiesHero";

const Activities = () => {
  return (
    <div>
      <ActivitiesHero />
      <Festival />
      <Seminar />
      <Concert />
    </div>
  );
};

export default Activities;
