import React, { useState } from "react";
import { AiFillCaretRight } from "react-icons/ai";

const Festival = () => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div className="flex flex-col w-full py-[80px] px-4 xl:px-0 bg-white">
      <div className="max-w-[1200px] grid grid-cols-1 md:grid-cols-2 items-center gap-16 mx-auto w-full">
        <div className="">
          <h2 className="font-bold text-[30px] mb-4">FILM festival</h2>
          <p className="mb-4">
            Popularly called the FILM festival, the Biennial Indigenous
            Liturgical Music Festival is our signature event. Bringing together
            choristers from tertiary institutions all over Nigeria, it features
            themed original liturgical music compositions by our affiliate
            choirs across three categories:
          </p>
          <div className="flex gap-2 mb-4">
            <AiFillCaretRight color="#13620B" size={100} />
            <p>
              Hymn/Common of the mass: In this category, each affiliate choir
              composes an original liturgical music setting either for a common
              of the Mass or Hymn to be used at mass. The composition is based
              on cultural, linguistic, and idiomatic materials drawn from, and
              reflecting the philosophical and aesthetic values of, the
              originating choir’s locale.
            </p>
          </div>
          <div className="flex gap-2 mb-4">
            <AiFillCaretRight color="#13620B" size={45} />
            <p>
              Hymn II/Motet I: Also, reflecting the based on the ethno-cultural
              context of each originating choir’s local, this category features
              a second liturgical hymn, or a motet.
            </p>
          </div>
          {showMore && (
            <>
              <div className="flex gap-2 mb-4">
                <AiFillCaretRight color="#13620B" size={45} />
                <p>
                  Motet II/Hymn III: In this category, the choirs compose a
                  third liturgical hymn, or a second motet. However, unlike in
                  the first two categories, the compositions here draw from, and
                  reflect on any other indigenous Nigerian culture, apart from
                  that of the originating choir’s locale
                </p>
              </div>
              <div className="flex gap-2 mb-4">
                <p>
                  Each of the original pieces from the participating choirs is
                  forwarded to their respective (Arch)Diocesan Liturgical Music
                  Commissions for certification of their liturgical
                  appropriateness. Subsequently, the pieces are premiered at the
                  festival, which is hosted by the institution of one of the
                  affiliate choirs. In addition, each participating choir
                  renders a set Gregorian chant piece. With hosting rotated in
                  alphabetical order around the provinces, the festival provides
                  an avenue for our members to experience multiple cultures,
                  visit diverse locations, and interact with their peers, from
                  all around Nigeria.
                </p>
              </div>
            </>
          )}
          <button
            className="bg-primary text-white py-2 px-4 hover:bg-black transform transition duration-500 ease-in-out hover:scale-110"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? "Read Less" : "Read More"}
          </button>
        </div>
        <img
          src="/festival.jpg"
          alt="hero"
          className="rounded-md h-[350px] object-cover "
        />
      </div>
    </div>
  );
};

export default Festival;
