import { Link } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";

const Footer = () => {
  return (
    <div className="bg-primary pt-[50px] z-0 px-[20px] md:px-[70px] overflow-hidden">
      <div className="hidden md:grid md:grid-cols-5 text-white">
        <div className="col-span-1 md:col-span-2">
          <div className="mb-[40px]">
            <img
              src="logo.png"
              alt="logo"
              style={{
                height: "max-content",
                width: "120px",
                objectFit: "cover",
              }}
            />
          </div>
          <p className="text-white text-[20px] mb-8">hello@film.ng</p>
          <div className="flex gap-4">
              <img
                src="/facebook.svg"
                className="cursor-pointer"
                alt="facebook logo"
                style={{ height: "18px", width: "18px" }}
              /> 
            {/* <a href="#" target="_blank" rel="noreferrer"> */}
              <img
                src="/instagram.svg"
                className="cursor-pointer"
                alt="facebook logo"
                style={{ height: "18px", width: "18px" }}
              />
            {/* </a> */}
            {/* <a href="#" target="_blank" rel="noreferrer"> */}
              <img
                src="/twitter.svg"
                className="cursor-pointer"
                alt="facebook logo"
                style={{ height: "18px", width: "18px" }}
              />
            {/* </a> */}
            {/* <a href="#" target="_blank" rel="noreferrer"> */}
              <img
                src="/linkedin.svg"
                className="cursor-pointer"
                alt="facebook logo"
                style={{ height: "18px", width: "18px" }}
              />
            {/* </a> */}
          </div>
        </div>
        <div className="col-span-1">
          <h2 className="text-[30px] font-bold mb-[36px]">Subscribe</h2>
          <div className="flex gap-2 items-center">
            <input
              type="email"
              placeholder="Your Email"
              className="bg-transparent w-[145px] focus:outline-none border border-white rounded-lg py-2 px-2 placeholder:text-white"
            />
            <div className="bg-white cursor-pointer w-[40px] h-[40px] rounded-lg justify-center items-center flex">
              <BiChevronRight color="#000" size={30} />
            </div>
          </div>
        </div>
        <div className="">
          <h2 className="font-bold text-[30px] mb-[36px]">Company</h2>
          <Link to="/about">
            <p className="text-[20px] text-white cursor-pointer mb-[20px]">
              About us
            </p>
          </Link>
          <Link to="/contact">
            <p className="text-[20px] text-white cursor-pointer mb-[20px]">
              Contact us
            </p>
          </Link>
          <Link to="/partners">
            <p className="text-[20px] text-white cursor-pointer mb-[20px]">
              Partners
            </p>
          </Link>

          <Link to="/team">
            <p className="text-[20px] text-white cursor-pointer  font-glacial mb-[20px]">
              Team
            </p>
          </Link>
        </div>
        <div>
          <h2 className="font-bold text-[30px] mb-[36px]">Legal</h2>
          <Link to="/privacy-policy">
            <p className="text-[20px] text-white cursor-pointer  font-glacial mb-[20px]">
              Privacy policy
            </p>
          </Link>
          <Link href="terms">
            <p className="text-[20px] text-white cursor-pointer  font-glacial mb-[20px]">
              Terms & conditions
            </p>
          </Link>
        </div>
      </div>
      <hr className="w-full hidden md:block mb-[50px]" />
      <div className="w-full hidden md:flex justify-end mt-[16px]">
        <p className="text-[20px] text-white font-glacial mb-[70px]">
          © Copyright 2023. film app
        </p>
      </div>
      <div className="grid grid-cols-2 gap-4 md:hidden">
        <div className="">
          <h2 className="font-bold text-white text-[26px] mb-[36px]">
            Company
          </h2>
          <Link to="/about">
            <p className="text-[20px] text-white cursor-pointer mb-[20px]">
              About us
            </p>
          </Link>
          <Link to="/contact">
            <p className="text-[20px] text-white cursor-pointer mb-[20px]">
              Contact us
            </p>
          </Link>
          <Link to="/partners">
            <p className="text-[20px] text-white cursor-pointer mb-[20px]">
              Partners
            </p>
          </Link>

          <Link to="/team">
            <p className="text-[20px] text-white cursor-pointer  font-glacial mb-[20px]">
              Team
            </p>
          </Link>
        </div>
        <div>
          <h2 className="font-bold text-[26px] text-white mb-[36px]">
            Legal
          </h2>
          <Link to="/privacy-policy">
            <p className="text-[20px] text-white cursor-pointer  font-glacial mb-[20px]">
              Privacy policy
            </p>
          </Link>
          <Link to="/terms">
            <p className="text-[20px] text-white cursor-pointer  font-glacial mb-[20px]">
              Terms & conditions
            </p>
          </Link>
        </div>
      </div>
      <div className="grid mt-[20px] grid-cols-2 md:hidden">
        <div className="">
          <div className="mb-[40px]">
            <img
              src="logo.png"
              alt="logo"
              style={{
                height: "max-content",
                width: "80px",
                objectFit: "cover",
              }}
            />
          </div>
          <p className="font-glacial text-white text-[20px] mb-8">filmApp.ng</p>
          <div className="flex gap-4">
            {/* <a href="#" target="_blank" rel="noreferrer"> */}
              <img
                src="/facebook.svg"
                className="cursor-pointer"
                alt="facebook logo"
                style={{ height: "18px", width: "18px" }}
              />
            {/* </a> */}
            {/* <a href="#" target="_blank" rel="noreferrer"> */}
              <img
                src="/instagram.svg"
                className="cursor-pointer"
                alt="facebook logo"
                style={{ height: "18px", width: "18px" }}
              />
            {/* </a> */}
            {/* <a href="#" target="_blank" rel="noreferrer"> */}
              <img
                src="/twitter.svg"
                className="cursor-pointer"
                alt="facebook logo"
                style={{ height: "18px", width: "18px" }}
              />
            {/* </a> */}
            {/* <a href="#" target="_blank" rel="noreferrer"> */}
              <img
                src="/linkedin.svg"
                className="cursor-pointer"
                alt="facebook logo"
                style={{ height: "18px", width: "18px" }}
              />
            {/* </a> */}
          </div>
        </div>
        <div className="">
          <h2 className="text-white text-[26px] mb-[36px]">Subscribe</h2>
          <div className="flex gap-2 items-center">
            <input
              type="email"
              placeholder="Your Email"
              className="bg-transparent w-[120px] focus:outline-none border border-white rounded-lg py-2 px-2 placeholder:text-white"
            />
            <div className="bg-white cursor-pointer w-[40px] h-[40px] rounded-lg justify-center items-center flex">
              <BiChevronRight color="#000" size={30} />
            </div>
          </div>
        </div>
      </div>
      <hr className="w-full md:hidden mt-[50px] mb-[20px]" />
      <div className="w-full flex md:hidden justify-end mt-[16px]">
        <p className="text-[20px] text-white mb-[70px]">
          © Copyright 2023. film app
        </p>
      </div>
    </div>
  );
};

export default Footer;
