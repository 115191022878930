import React, { useState, useEffect } from 'react';

const NewsTicker = ({ headlines }) => {
    const [activeHeadlineIndex, setActiveHeadlineIndex] = useState(0);
    const [translateValue, setTranslateValue] = useState('120%');
  useEffect(() => {
    // Slide in the headline smoothly
    const slideInTimer = setTimeout(() => {
      setTranslateValue('0%');
    }, 100);

    // After the headline is shown for 5 seconds, reset its position and move to the next headline
    const switchHeadlineTimer = setTimeout(() => {
      setTranslateValue('120%');
      setActiveHeadlineIndex((prevIndex) => (prevIndex + 1) % headlines.length);
    }, 5200); // 200ms for the transition duration + 5000ms for displaying the headline

    return () => {
      clearTimeout(slideInTimer);
      clearTimeout(switchHeadlineTimer);
    };
  }, [activeHeadlineIndex, headlines]);



  return (
    <div className="bg-gray-800 text-white p-4 overflow-hidden h-14">
      <div
        className={`flex justify-center items-center text-[18px] transform transition-transform duration-200 translate-x-${translateValue}`}
      >
        {headlines[activeHeadlineIndex]}
      </div>
    </div>
  );
};

export default NewsTicker;
