import React, { useState } from "react";
import { AiFillCaretRight } from "react-icons/ai";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";


const WhatWeDo = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [showMore, setShowMore] = useState(false);
  return (
    <div className="flex flex-col w-full py-[80px] px-4 md:px-0 bg-[#F5F5F5]">
      <div className="max-w-[1200px] grid grid-cols-1 md:grid-cols-2 items-center gap-16 mx-auto w-full">
        <img
          src="/do.jpg"
          alt="hero"
          className="rounded-md order-2 md:order-1"
          data-aos="zoom-in"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
          data-aos-once="true"
        />
        <div
          className="order-1 md:order-2"
          data-aos="fade-up"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
          data-aos-once="true"
        >
          <h2 className="font-bold text-[30px] mb-4">What We Do?</h2>
          <p className="mb-4">
            In line with the reforms of the Second Vatican Council, our primary
            objective is to harness tertiary-institution-based research minds
            (staff and students) in proactively providing well thought-out
            liturgical music in our indigenous languages, based on the musical
            styles, genres, and idioms of our indigenous cultures, for the
            Catholic Church in Nigeria, as well as the universal Church. To
            achieve this objective, we:
          </p>
          <div className="flex gap-2 mb-4">
            <AiFillCaretRight color="#13620B" size={30} />
            <p>
              Engender research into the vocal and instrumental music genres and
              styles of our indigenous cultures.
            </p>
          </div>
          <div className="flex gap-2 mb-4">
            <AiFillCaretRight color="#13620B" size={45} />
            <p>
              Adapt the findings from such research in the composition of
              congregational, as well as Liturgical, music for worship in our
              particular and universal Church.
            </p>
          </div>
          {showMore && (
            <>
              <div className="flex gap-2 mb-4">
                <AiFillCaretRight color="#13620B" size={30} />
                <p>
                  Compose and promote Liturgical Marian music; since Mother Mary
                  has been, ab initio, the Patron of FILM.
                </p>
              </div>
              <div className="flex gap-2 mb-4">
                <AiFillCaretRight color="#13620B" size={35} />
                <p>
                  Engage the youths in varied constructive activities aimed at
                  deepening their knowledge of, and love for, our Catholic
                  faith.
                </p>
              </div>
              <div className="flex gap-2 mb-4">
                <AiFillCaretRight color="#13620B" size={40} />
                <p>
                  Endeavour to create a new vista of interest, in our youth, of
                  the Latin chants as the original and pristine liturgical music
                  of the Church
                </p>
              </div>
              <div className="flex gap-2 mb-4">
                <AiFillCaretRight color="#13620B" size={52} />
                <p>
                  Organise events and activities targeted at reawakening and
                  appreciating our cultural values and sensibilities, while
                  developing the musical knowledge and skills of our young
                  choristers.
                </p>
              </div>
              <div className="flex gap-2 mb-4">
                <AiFillCaretRight color="#13620B" size={20} />
                <p>
                  Promote cultural/ethnic tolerance and unity among our people.
                </p>
              </div>
            </>
          )}
          <button
            className="bg-primary text-white py-2 px-4 hover:bg-black transform transition duration-500 ease-in-out hover:scale-110"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? "Read Less" : "Read More"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
