import React, { useEffect, useState } from "react";
import { client, urlFor } from "../../client";
import { Link } from "react-router-dom";
import NewsTicker from "./NewsTicker";

const NewsCard = () => {
  const [news, setNews] = useState([]);
  console.log(news);

  useEffect(() => {
    const query = '*[_type == "announcement"]';
    client.fetch(query).then((data) => setNews(data));
  }, []);

  const headlines = news?.map((data) => (
    <Link to={data._id}>{data.title}</Link>
  ));

  return (
    <div className="flex flex-col bg-[#f6f6f6] w-full px-6">
      <div className="max-w-[1280px] mx-auto w-full">
        <h2 className="font-medium my-2 text-[35px] text-black">Headlines</h2>
        {news && <NewsTicker headlines={headlines} />}
      </div>
      <div className="w-full pt-[50px] pb-[80px] max-w-[1280px] mx-auto">
        <h2 className="font-medium mb-12 text-[30px] text-black">
          Latest News
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {news.map((info) => (
            <div className="shadow-md bg-white">
              {info.imgUrl && <img src={urlFor(info.imgUrl)} alt="news" />}
              <div className="px-4 pt-4 pb-6">
                <h2 className="text-black font-bold text-[24px]">
                  {info.title}
                </h2>
                <p className="mb-4 mt-2">
                  {info.description.length > 150
                    ? `${info.description.substring(0, 150)}...`
                    : info.description}
                </p>
                <Link
                  to={`/news/${info._id}`}
                  className="bg-primary text-white py-2 px-4 hover:bg-black transform transition duration-500 ease-in-out hover:scale-110 mt-4"
                >
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsCard;
